import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import { Link } from 'gatsby';
import gsap from 'gsap';

export const playOverlay = duration => {
  gsap.set('#music-transition-overlay', { display: 'block', x: '-100%' });
  gsap.set('#music-transition-overlay .bars .bar', { y: '100%', opacity: 1 });
  console.log('duration', duration);

  gsap.fromTo(
    '#music-transition-overlay',
    { x: '-100%' },
    {
      display: 'block',
      x: 0,
      ease: 'power4.inOut',
      duration: duration !== 'undefined' ? duration : 0.8,
    }
  );
};

export const playIntro = () => {
  const initWave = gsap.timeline({ delay: 0.3 });

  // playOverlay();

  // initWave.set('#music-transition-overlay', { display: 'block' });
  // initWave.set("#music-transition-overlay .bars .bar", { y: "100%", opacity: 1 });
  // initWave.fromTo('#music-transition-overlay', { x: "-100%" }, { display: 'block', x: 0, ease: "power4.inOut", duration: 0.8 });

  // Wave
  initWave.to(
    '#music-transition-overlay .bars .bar',
    {
      y: '50%',
      scaleY: 1.3,
      duration: 1,
      ease: 'elastic.out(1, 0.3)',
      stagger: {
        each: 0.01,
        from: 'start',
      },
    },
    '-=0.3'
  );

  initWave.set('#music-transition-overlay .bars .bar', {
    scaleY: 0.65,
    y: '17.5%',
  });
};

export const playOutro = () => {
  const outro = gsap.timeline();

  outro.fromTo(
    '#music-transition-overlay .bars .bar',
    {
      y: '17.5%',
      scaleY: 0.65,
    },
    {
      y: '17.5%',
      scaleY: 0,
      opacity: 0,
      duration: 0.25,
      ease: 'power4.in',
      stagger: {
        each: 0.01,
        from: 'end',
      },
    }
  );

  outro.to(
    '#music-transition-overlay',
    { x: '100%', duration: 1, ease: 'power4.inOut' },
    '-=0.7'
  );
};

const PotockLink = ({ to, children, ...rest }) => {
  if (to === '/') {
    if (true) {
      return (
        <TransitionLink
          to={to}
          {...rest}
          exit={{
            trigger: ({ exit, node }) => {
              playOverlay(0.8);
            },
            length: 0.8,
          }}
          entry={{
            delay: 0.8,
            // trigger: () => {
            //   console.log("enter")
            //   playOverlay(0.8);
            //   // playIntro();
            //   // gsap.set('#music-transition-overlay', { display: 'block'});
            //   // gsap.set("#music-transition-overlay .bars .bar", { y: "100%", opacity: 1 });
            //   gsap.delayedCall(1.5, playOutro);
            // }
          }}
        >
          {children}
        </TransitionLink>
      );
    } else {
      return (
        <TransitionLink
          to={to}
          {...rest}
          exit={{
            trigger: ({ exit, node }) => {
              console.log('exit!');
              playOverlay(0.8);
              gsap.delayedCall(0, playIntro);

              // gsap.delayedCall(0, playIntro);
              // playIntro();
              // gsap.delayedCall(1.1, playEqualizer);
              // gsap.delayedCall(1.5, playOutro);
            },
            length: 2,
          }}
          entry={{
            appearAfter: 1.5,
            delay: 0,
            trigger: () => {
              console.log('enter');
              playOverlay(0.8);
              // playIntro();
              // gsap.set('#music-transition-overlay', { display: 'block'});
              // gsap.set("#music-transition-overlay .bars .bar", { y: "100%", opacity: 1 });
              gsap.delayedCall(1.5, playOutro);
            },
          }}
        >
          {children}
        </TransitionLink>
      );
    }
  } else {
    return (
      <AniLink hex="#9c1b1b" paintDrip {...rest} to={to}>
        {children}
      </AniLink>
    );
  }
};

// const PotockLink = ({props, children}) => {
//     return <TransitionLink
//         exit={{
//             length:
//         }}

//         entry={{

//         }}

//         {...props}>{children}</TransitionLink>
// }

export default PotockLink;
